import React, { Component } from "react";
import { Switch, Select } from 'antd';
import "./Header.scss"
import 'font-awesome/css/font-awesome.min.css';

const { Option } = Select;

//allows the user to change language
class Header extends Component {

	render() {
		const langDescMap = {
			python3: 'Python 3',
			ruby3: 'Ruby 3',
			javascript: 'Javascript',
			cpp: 'C++',
			java: 'Java',
			c: 'C'
		}
		let options = this.props.languageList.map((l,index) => (<Option key={index} value={l}>{langDescMap[l]}</Option>))
		return(
			<div styleName="EditorHeader">
				<div styleName="headerLeft">Code Editor</div>
				<div styleName="headerRight">
					<div style={{flex:"1 0 auto"}}>
						<span style={{fontSize:"1.15rem", marginRight: "0.5vw"}}>Mode</span>
						<Switch checkedChildren="Light" unCheckedChildren="Dark" defaultChecked onChange={this.props.toggleTheme} />
					</div>
					<Select value={this.props.selectedLanguage} id="menuList" style={{ width: "8vw", fontSize: "1.15rem" }} onChange={(e) => this.props.switchLanguage(e)} >
						{options}
					</Select>
					<i className={this.props.editorExpand?"fa fa-compress":"fa fa-expand"}
						styleName="toggleExpandButton"
						onClick={()=> this.props.toggleExpand()}
						title={this.props.editorExpand?"Restore Editor":"Maximize Editor"} 
					/>
				</div>
			</div>
		)
	}
}

export default Header;
