import React, { Component } from "react";
import { Select, Icon, Button, Switch } from 'antd';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"

import "./EditorHeader.scss"
import 'font-awesome/css/font-awesome.min.css';

const { Option } = Select;

//allows the user to change language
class InterviewHeader extends Component {

	toggleSync = (val) => {
		this.props.syncCursor(val)
	}

	render() {
		const langDescMap = {
			python3: 'Python 3',
			ruby3: 'Ruby 3',
			python2: 'Python 2',
			javascript: 'Javascript',
			cpp: 'C++',
			java: 'Java',
			c: 'C'
		}
		let options = this.props.languageList.map((l,index) => (<Option key={index} value={l}>{langDescMap[l]}</Option>))
	
		return(
			<div styleName="EditorHeader">
				<div styleName="headerLeft">Code Editor</div>
				<div styleName="headerRight">
					<Select value={this.props.selectedLanguage} id="menuList" style={{ width: "8vw", fontSize: "0.8vw" }} onChange={(e) => this.props.switchLanguage(e)} >
						{options}
					</Select>
					{this.props.editorExpand ? <div styleName="toggleExpandButton" onClick={()=> this.props.toggleExpand()}><Icon type="fullscreen-exit" /> </div>: <div styleName="toggleExpandButton" onClick={()=> this.props.toggleExpand()}> <Icon type="fullscreen" /></div>}
					<div>
						<span style={{fontSize:"1rem", marginRight: "0.5vw"}}>Sync cursor</span>
						<Switch onChange={this.toggleSync} />
					</div>
				</div>
				<Button
				styleName="form__button"
				onClick={() =>
					this.props.submit()
				}
				>
					Run Code
				</Button>
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
	};
};

const mapStateToProps = state => {
	return {
		participants: state.interview.participants,
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InterviewHeader));