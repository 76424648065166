import React, { Component } from 'react'
import { Empty } from 'antd';

import "./EmptyNotes.scss"

class EmptyNotes extends Component {

    render() {
        return (
            <div styleName='c-empty-notes'>
                <div styleName='c-empty-notes__header'>
                    <h3 styleName='c-empty-notes__header-text'>Your Notes</h3>
                </div>
                <div styleName='c-empty-notes__body'>
                    <Empty description="There are no notes for this interview."/>
                </div>
            </div>
        )
    }
}

export default EmptyNotes;