export const GACategories =  {
    assessment: "ASSESSMENT",
    auth: "AUTH",
    error: "ERROR"
}

export const GAActions = {
    switchTab: "SWITCH_TAB",
    toggleEditor: "TOGGLE_EDITOR",
    solveQuestion: "SOLVE/REATTEMPT_QUESTION",
    testCode: "TEST_CODE",
    runCode: "RUN_CODE",
    submitTest: "SUBMIT_TEST_CLICK",
    changeLanguage: "CHANGE_LANGUAGE",
    startTest: "START_TEST",
    resumeTest: "RESUME_TEST",
    javascriptError: "JAVASCRIPT_ERROR",
    googleLogin: "GOOGLE_LOGIN"

}

export const GALabels = {
    "Results": "RESULTS",
    "Problem Statement": "PROBLEM_STATEMENT",
    "Custom Input": "CUSTOM_INPUT",
    "c": "C",
    "java": "JAVA",
    "python3": "PYTHON3"

}