import { createAction } from "redux-action";
import axios from "axios";
import { AxiosCallError, AxiosCallRequest, AxiosCallSuccess} from "./commonActions";
import { get_service_endpoint } from "../../ServiceEndpoints.js";

let ep = get_service_endpoint("interview")

export const setLastCode = createAction('SET_LAST_CODE')
export const setLastLanguage = createAction('SET_LAST_LANGUAGE')
export const setChatEvents = createAction('SET_CHAT_EVENTS')
export const getSupportedLanguages = createAction("GET_SUPPORTED_LANGUAGES")
export const setNotes = createAction("SET_NOTES")
export const updateNote = createAction("UPDATE_NOTE")
export const setInitialNotes = createAction("SET_INITIAL_NOTES")
export const setEndInterviewUserDetail = createAction('SET_END_INTERVIEW_USER_DETAIL')
export const getInterviewSuccess = createAction('GET_INTERVIEW_SUCCESS')
export const setParticipants = createAction("SET_PARTICIPANTS")
export const setHeartbeat = createAction("SET_HEARTBEAT")

export const submitInterviewCode = (code, language) => {
	return (dispatch, getState) => {
		dispatch(AxiosCallRequest())
		let interview_id = getState().interview.interview_id
		let url=`${ep}/api/interview/${interview_id}/code_submissions/`
		axios.post(url,{
			"code": code,
			"language": language
		}).then(res => {
				dispatch(AxiosCallSuccess())
			})
			.catch( error => {
			dispatch(AxiosCallError(error))
			})
	}
}

export const fetchLatestEditorEvent = (interview_id, language) => {
	return dispatch => {
		let url;
		if (language)
			url = `${ep}/api/interview/${interview_id}/editor_events/latest/?language=${language}`
		else
			url=`${ep}/api/interview/${interview_id}/editor_events/latest/`
		return axios.get(url)
		.then(res => {
			if (res.data.msg !== "Does Not Exist") {
				dispatch(setLastCode(res.data.data))
				dispatch(setLastLanguage(res.data.language))
			}
			else
				dispatch(setLastCode(''))
		})
		.catch(error => {
			dispatch(AxiosCallError(error))
		})
	}
}

export const fetchChatEvents = (interview_id) => {
	return dispatch => {
		let url=`${ep}/api/interview/${interview_id}/chat_events/`
		return axios.get(url)
		.then(res => {
			dispatch(setChatEvents(res.data))
		})
		.catch(error => {
			dispatch(AxiosCallError(error))
		})
	}
}

export const fetchNotes = (interview_id) => {
	return dispatch => {
		let url=`${ep}/api/interview/${interview_id}/user_notes/`
		return axios.get(url)
		.then(res => {
			dispatch(setInitialNotes(res.data))
		})
		.catch(error => {
			dispatch(AxiosCallError(error))
			dispatch(setInitialNotes([]))
		})
	}
}

export const fetchData = (interview_id) => {
	let promise_array = []
	return (dispatch, getState) => {
		dispatch(AxiosCallRequest())
		promise_array.push(dispatch(fetchLatestEditorEvent(interview_id)))
		promise_array.push(dispatch(fetchChatEvents(interview_id)))
		if(localStorage.getItem("role") === "IN")
			promise_array.push(dispatch(fetchNotes(interview_id)))
		dispatch(AxiosCallSuccess())
		return Promise.all(promise_array)
	}
}

export const getLanguages = () => {
	return dispatch => {
		dispatch(AxiosCallRequest());
		let ep1 = get_service_endpoint("copdg");
		let url = `${ep1}/api/supported_languages/`
		axios
			.get(url)
			.then(response => {
				dispatch(getSupportedLanguages(response.data.languages));
				dispatch(AxiosCallSuccess());
			})
			.catch(err => {
				dispatch(AxiosCallError(err));
			});
	}
}

export const logout = () => {
	return (dispatch) => {
		localStorage.clear();
		delete axios.defaults.headers.common["X-Access-Token"];
		delete axios.defaults.headers.common["X-Refresh-Token"];
	}
}

export const postNote = (data, callback) => {
	return (dispatch, getState) => {
		let interview_id = getState().interview.interview_id
		let url=`${ep}/api/interview/${interview_id}/user_notes/`
		axios
		.post(url, {data})
		.then(response => {
			dispatch(setNotes(response.data))
			if (callback)
				callback()
		})
		.catch(err => {
			dispatch(AxiosCallError(err));
			if (callback)
				callback()
		});
	}
}

export const editNote = (note_id, fire_time, data) => {
	return (dispatch, getState) => {
		let interview_id = getState().interview.interview_id
		let url=`${ep}/api/interview/${interview_id}/note?fire_time=${fire_time}&id=${note_id}`
		axios
		.patch(url, {data})
		.then(response => {
			dispatch(updateNote(response.data))
		})
		.catch(err => {
			dispatch(AxiosCallError(err));
		});
	}
}

export const deleteNote = (note_id, fire_time) => {
	return (dispatch, getState) => {
		let interview_id = getState().interview.interview_id
		let url=`${ep}/api/interview/${interview_id}/note?fire_time=${fire_time}&id=${note_id}`
		axios
		.delete(url)
		.then(response => {
			dispatch(fetchNotes(interview_id))
		})
		.catch(err => {
			dispatch(AxiosCallError(err));
		});
	}
}

export const submitFeedback = (data, callback) => {
	return (dispatch, getState) => {
		let email_id = localStorage.getItem("username")
		let participants = getState().interview.participants
		let participant_id = participants.find(item => item.email === email_id).id
		let url=`${ep}/api/participant/${participant_id}/feedback/`;
		axios.patch(url, data)
		.then(response => {
			if (callback)
				callback()
			dispatch(logout())
		})
		.catch(error => {
			dispatch(AxiosCallError(error));
		});	
	}
}

export const getInterview = (interview_id) => {
	return (dispatch, getState) => {
		let url=`${ep}/api/interview/${interview_id}/`;
		return axios.get(url)
		.then(response => {
			dispatch(getInterviewSuccess(response.data))
		})
		.catch(error => {
			dispatch(AxiosCallError(error));
		});	
	}
}

export const checkNetworkConnection = () => {
	return dispatch => {
		dispatch(AxiosCallRequest());
		let ep = get_service_endpoint("copdg");
		let url = `${ep}/api/supported_languages/`
		return axios.get(url)
	}
}