import axios from "axios";
import { createAction } from "redux-action";
import { get_service_endpoint } from "../../ServiceEndpoints.js";
import {postEvent} from "./AssessmentActions"
import { AssessmentStatus } from "../../store/reducers/InviteReducer";

//let ep = get_service_endpoint("cs-auth");

// let ep = "http://127.0.0.1:8000";

const errorType = "404";

let fsep = get_service_endpoint("cs-fileupload");

export const AxiosCallRequest = createAction("AXIOS_CALL_REQUEST");

export const AxiosCallError = createAction("AXIOS_CALL_ERROR");

export const AxiosCallSuccess = createAction("AXIOS_CALL_SUCCESS");

export const onLoadModal = createAction("ON_LOAD_MODAL");

export const requestApi = createAction("REQUEST_API");

export const onToggleAuthModal = createAction("ON_TOGGLE_AUTH_MODAL");

export const onLocationChange = createAction("@@react-router/LOCATION_CHANGE");

export const Download = createAction("DOWNLOAD");

export const resetEntityTypeAndID = createAction("RESET_ENTITY_TYPE_AND_ID");

export const showErrorPage = createAction("SHOW_ERROR_PAGE");

export const setCurrentPage = createAction("SET_CURRENT_PAGE")

export const WSConnected = createAction("WEBSOCKET_CONNECTED")

export const WSDisconnected = createAction("WEBSOCKET_DISCONNECTED")

export const deleteFileFromServer = (payload) => {
  return () => {
    axios.delete(`${fsep}/api/files/${payload.fileId}/`);
  };
};

export const attemptToDownloadFile = (payload) => (dispatch) =>
  dispatch(Download({ type: "DOWNLOAD_ATTEMPT", data: payload }));

export const downloadFile = (payload) => (dispatch) =>
  dispatch(Download({ type: "DOWNLOAD_SUCCESS", data: payload }));

export const pageNotFound = createAction("PAGE_NOT_FOUND");

export const resetPageNotFound = () => {
  return (dispatch) => {
    dispatch(pageNotFound(false));
  };
};

export const setPageNotFound = (errorHeading, errorMessage) => {
  return (dispatch) => {
    dispatch(
      pageNotFound({
        pageNotFound: true,
        errorHeading,
        errorMessage,
        errorType,
      })
    );
  };
};

export const documentLostFocus = () => {
  return (dispatch, getState) => {
    let hasClass = document.getElementsByClassName("ant-modal ant-modal-confirm ant-modal-confirm-warning");
    let assessment_status = getState().invite.assessment_status
    //checking if modal is already open or not
    if (hasClass.length <= 0 && assessment_status === AssessmentStatus.InProgress) {
      dispatch(
        onLoadModal({
          showModal: true,
          modalType: "Do not navigate away!",
          modalContent:
            "You have navigated away from the test by switching tab, switching window, minimizing window, locking screen or clicking outside the webpage. This has been recorded on your assessment, please do not perform any of these actions again as it may result in disqualification.",
        })
      );
      let data = {
        type: "NAVIGATED_AWAY",
        subtype: "TAB_SWITCH"
			}
			let current_page = getState().events.current_page
			let curr_problem_id
			if (current_page === 'QP')
				curr_problem_id = getState().assessment.problems.find( p => p.isCurrent === true).id
			if (curr_problem_id) {
				data['data'] = {
					page: current_page,
					prob_id: curr_problem_id
				} 
			}
			else {
				data['data'] = {
					page: current_page
				}
			}
      //prevents tab switch event from firing if the event was for tab close or page reload
      if (!sessionStorage.getItem("tab_closed"))
        dispatch(postEvent(data))
    }
  };
};

export const tabClose = () => {
  return (dispatch, getState) => {
    let data = {
      type: "NAVIGATED_AWAY",
      subtype: "CLOSE"
    }
    if (getState().invite.assessment_status === AssessmentStatus.InProgress)
      dispatch(postEvent(data))
  }
}

export const refreshTab = () => {
  return (dispatch, getState) => {
    let data = {
      type: "NAVIGATED_AWAY",
      subtype: "RELOAD"
    }
    if (getState().invite.assessment_status === AssessmentStatus.InProgress)
      dispatch(postEvent(data))
  }
}
