import React from "react"
import {Input, Comment, Avatar, Tooltip } from "antd"
import moment from "moment";
import { SendOutlined } from "@ant-design/icons";
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"


import "./ChatContainer.scss"

const AlwaysScrollToBottom = () => {
	const elementRef = React.useRef();
	React.useEffect(() => elementRef.current.scrollIntoView());
	return <div ref={elementRef} />;
};

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			message: "",
			lastMessage: ""
        }
    }
    
    handleMessage = (e) => {
        this.setState({message: e.target.value})
    }

    sendMessage = () => {
        if (this.state.message) {
            this.props.sendMessage(this.state.message)
            this.setState({message: ""})
        }
	}

	scrollToBottom = () => {
		this.state.lastMessage.scrollIntoView({ behavior: 'smooth' })
	}

	renderOldChat = () => {
		let chat_array = []
		this.props.chat_data.forEach((item, index, arr) => {
			let participant = this.props.participants.find(p => p.user_id === item.created_by)
			if(participant)
				var full_name = participant.name
			let fire_time = moment(item.fire_time).format('YYYY-MM-DD HH:mm:ss')
			if (item.type && item.type === "entry") {
				chat_array.push(
					<div key={index} styleName="chat-userstatus" >
						<p >{item.created_by === this.props.user_id?"You":full_name} entered the chat</p>
					</div>
				)
			}
			else if (item.type && item.type === "exit") {
				chat_array.push(
					<div key={index} styleName="chat-userstatus">
						<p>{item.created_by === this.props.user_id?"You":full_name} left the chat</p>
					</div>
				)
			}
			else if (item.created_by === this.props.user_id) {
				chat_array.push(<Comment
					styleName="chat__self"
					key={index}
					content={
					<p styleName="chat-self__message">
						{item.data}
					</p>
					}
					datetime={
					<Tooltip title={moment(item.fire_time).format("DD-MM-YY, h:mm:ss a")}>
						<span>{moment(fire_time).fromNow()}</span>
					</Tooltip>
					}
				/>)
			}
			else {
				chat_array.push(<Comment
					styleName="chat__other"
					key={index}
					author={<a>{full_name}</a>}
					avatar={
					<Avatar>{full_name.charAt(0).toUpperCase()}</Avatar>
					}
					content={
					<p styleName="chat-other__message">
						{item.data}
					</p>
					}
					datetime={
					<Tooltip title={moment(item.fire_time).format("DD-MM-YY, h:mm:ss a")}>
						<span>{moment(item.fire_time).fromNow()}</span>
					</Tooltip>
					}
				/>)
			}
		})
		return chat_array
	}

    renderChat = () => {
		let chat_array = []
        this.props.chat.forEach( (item, index, arr) => {
			if (item.type && item.type === "entry") {
				chat_array.push(
					<div key={index} styleName="chat-userstatus" >
						<p >{item.user_id === this.props.user_id?"You":item.user} entered the chat</p>
					</div>
				)
			}
			else if (item.type && item.type === "exit") {
				chat_array.push(
					<div key={index} styleName="chat-userstatus">
						<p>{item.user_id === this.props.user_id?"You":item.user} left the chat</p>
					</div>
				)
			}
			else if (item.user_id === this.props.user_id) {
				chat_array.push(<Comment
					styleName="chat__self"
					key={index}
					content={
					<p styleName="chat-self__message">
						{item.message}
					</p>
					}
					datetime={
					<Tooltip title={moment(item.fire_time).format("DD-MM-YY, h:mm:ss a")}>
						<span>{moment(item.fire_time).fromNow()}</span>
					</Tooltip>
					}
				/>)
			}
			else {
				chat_array.push(<Comment
					styleName="chat__other"
					key={index}
					author={<a>{item.user}</a>}
					avatar={
					<Avatar>{item.user.charAt(0).toUpperCase()}</Avatar>
					}
					content={
					<p styleName="chat-other__message">
						{item.message}
					</p>
					}
					datetime={
					<Tooltip title={moment(item.fire_time).format("DD-MM-YY, h:mm:ss a")}>
						<span>{moment(item.fire_time).fromNow()}</span>
					</Tooltip>
					}
				/>)
			}
        })
        return chat_array
    }
	render() {
		return (
			 <div styleName='c-chat'>
				<div styleName='c-chat__header'>
						<span styleName="chat-status__icon" connected={this.props.connected?"true":"false"}></span>
						<p>Live Chat</p>
				</div>
				<div styleName='c-chat__body'>
					{this.props.chat_data.length>0?this.renderOldChat():null}
					{this.props.chat?this.renderChat():null}
					<AlwaysScrollToBottom />
			 	</div> 
				 <div styleName='c-chat__footer'>
					 <Input type="text" placeholder="Type a message..." value={this.state.message} onChange={this.handleMessage} onPressEnter={this.sendMessage}>
					 </Input>
					 <SendOutlined onClick={this.sendMessage} disabled={this.state.message?true: false}/>
			  </div>  
			</div>
		)	
	}
}

const mapDispatchToProps = dispatch => {
	return {
	};
};

const mapStateToProps = state => {
	return {
		user_id: state.auth.user_id,
		participants: state.interview.participants
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));