import React from "react";
import "./StatusBox.scss"

const StatusBox = props => {
    return (
        <div styleName="custom_io">
            <h3>{props.title}</h3>
            <textarea styleName={"custom_io_body" + (props.error ? " c-custom-input__output--errors" : "")} rows="4" cols="50" onChange={(event) => props.onChange(event.target.value)} value={props.value} readOnly={props.readOnly} />
            {props.children}
        </div>
    )
};

export default StatusBox;
