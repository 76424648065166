import { createAction } from "redux-action";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints.js";
import { AxiosCallError, requestApi, showErrorPage } from "./commonActions";
import { onLoadModal } from "./commonActions";
import { flash } from "./NotificationActions";
import { history } from '../../index'

let ep = get_service_endpoint("cs-auth");
let catalog_ep = get_service_endpoint("cs-course-catalog");

let ep2 = get_service_endpoint("interview")

export const loginSuccess = createAction("LOGIN_SUCCESS");

export const logoutSuccess = createAction("LOGOUT_SUCCESS");

export const signUpSuccess = createAction("SIGNUP_SUCCESS");

export const getUserInfoSuccess = createAction("GET_USER_INFO_SUCCESS");

export const getMatchingCitiesSuccess = createAction("GET_MATCHING_CITIES");

export const getAllStatesSuccess = createAction("GET_ALL_STATES");

export const FilterLocationsSuccess = createAction("FILTER_LOCATIONS");

export const getcompanyInfoSuccess = createAction("GET_COMPANY_INFO_SUCCESS")

export const getUserInfoRequest = () => {
	let axiosConfig = {
		headers: {
			"X-Access-Token": localStorage.getItem("access-token"),
			"X-Refresh-Token": localStorage.getItem("refresh-token")
		}
	};
	return dispatch => {
		axios.get(
			`${ep}/api/self/`, axiosConfig
		).then(response => {
			if (response.success) {
				dispatch(getUserInfoSuccess(response.data));
			}
			dispatch(requestApi(false));
		}).catch(error => {
			dispatch(AxiosCallError(error));
		});
	};
};

export const loginRequest = payload => {
	return dispatch => {
		dispatch(requestApi(true));
		axios.post(
			`${ep}/api/login/`, 
			{
				email: payload.emailId,
				password: payload.password
			}
		).then(response => {
			if (response.success) {
				localStorage.setItem("access-token", response.data["access_token"]);
				localStorage.setItem("refresh-token", response.data["refresh_token"]);
				axios.defaults.headers.common["X-Access-Token"] = response.data["access_token"];
				axios.defaults.headers.common["X-Refresh-Token"] = response.data["refresh_token"];
				dispatch(loginSuccess(response.data));
				dispatch(onLoadModal({ modalType: "", modalContent: "", showModal: false }));
				if (payload.callback) payload.callback();
			} else {
				dispatch(flash(
					{ message: "Invalid Username/Password", type: "failure" },
					2500
				));
			}
			dispatch(requestApi(false));
		}).catch(error => {
			dispatch(AxiosCallError(error));
		});
	};
};

export const loginUsingFacebook = payload => {
	return dispatch => {
		dispatch(requestApi(true));
		axios.post(`${ep}/api/social_login/`, payload)
		.then(response => {
			if (response.success) {
				localStorage.setItem("access-token", response.data["access_token"]);
				localStorage.setItem("refresh-token", response.data["refresh_token"]);
				axios.defaults.headers.common["X-Access-Token"] = response.data["access_token"];
				axios.defaults.headers.common["X-Refresh-Token"] = response.data["refresh_token"];
				dispatch(loginSuccess(response.data));
				dispatch(onLoadModal({ modalType: "", modalContent: "", showModal: false }));
				if (payload.callback) payload.callback();
			}
			dispatch(requestApi(false));
		}).catch(err => {
			dispatch(AxiosCallError(err));
		});
	};
};

export const loginUsingGoogle = payload => {
	return (dispatch, getState) => {
		dispatch(requestApi(true));
		axios.post(`${ep}/api/social_login/`, payload)
		.then(response => {
			if (response.success) {
				if (response.data.username === getState().invite.email_id.toLowerCase()) {
					localStorage.setItem("access-token", response.data["access_token"]);
					localStorage.setItem("refresh-token", response.data["refresh_token"]);
					axios.defaults.headers.common["X-Access-Token"] = response.data["access_token"];
					axios.defaults.headers.common["X-Refresh-Token"] = response.data["refresh_token"];
					dispatch(loginSuccess(response.data));
					dispatch(onLoadModal({ modalType: "", modalContent: "", showModal: false }));
					if (payload.callback) payload.callback();
				}
				else {
					dispatch(showErrorPage({
						'errorType': 'Application Error',
						'errorHeading': 'Unauthorized access',
						'errorMessage': 'Please make sure you are logged in as the user who received the invite'
					}))
					history.push('/error')
				}
			}
			dispatch(requestApi(false));
		}).catch(err => {
			dispatch(AxiosCallError(err));
		});
	};
};

export const loginUsingGoogleForInterview = (payload) => {
	return (dispatch, getState) => {
		dispatch(requestApi(true));
		
		axios.post(`${ep}/api/social_login/`, payload)
		.then(response => {
			let participant = getState().interview.participants.find(item => item.role === "CA")
			if (response.success) {
				if(participant.email === response.data.username){	
					localStorage.setItem("access-token", response.data["access_token"]);
					localStorage.setItem("refresh-token", response.data["refresh_token"]);
					localStorage.setItem("username", response.data["username"]);					
					let temp = window.location.hostname.split('.').reverse();
					let root_domain = '.' + temp[1] + '.' + temp[0];	
					document.cookie = "access_token=" + response.data["access_token"] + ";max-age=10800;domain="+root_domain;
					document.cookie = "refresh_token=" + response.data["refresh_token"] + ";max-age=10800;domain="+root_domain;
					axios.defaults.headers.common["X-Access-Token"] = response.data["access_token"];
					axios.defaults.headers.common["X-Refresh-Token"] = response.data["refresh_token"];
					dispatch(loginSuccess(response.data));
					dispatch(onLoadModal({ modalType: "", modalContent: "", showModal: false }));
					dispatch(fillUserId(response.data.user_id, response.data.username))
					if (payload.callback) payload.callback();
				}
				else {
					dispatch(showErrorPage({
						'errorType': 'Application Error',
						'errorHeading': 'Unauthorized access',
						'errorMessage': 'Please make sure you are logged in as the user who received the invite'
					}))
					history.push('/error')
				}
			}
			dispatch(requestApi(false));
		}).catch(err => {
			dispatch(AxiosCallError(err));
		});
	};
};

export const loginInterviewRequest = payload => {
	return (dispatch, getState) => {
		let participants = getState().interview.participants.filter(item => item.role === "IN")
		let valid_participant = participants.some(e => e.email===payload.emailId)
		if (valid_participant) {
			dispatch(requestApi(true));
			axios.post(
				`${ep}/api/login/`, 
				{
					email: payload.emailId,
					password: payload.password
				}
			).then(response => {
				if (response.success) {
					localStorage.setItem("access-token", response.data["access_token"]);
					localStorage.setItem("refresh-token", response.data["refresh_token"]);
					localStorage.setItem("username", response.data["username"]);
					let temp = window.location.hostname.split('.').reverse();
					let root_domain = '.' + temp[1] + '.' + temp[0];	
					document.cookie = "access_token=" + response.data["access_token"] + ";max-age=10800;domain="+root_domain;
					document.cookie = "refresh_token=" + response.data["refresh_token"] + ";max-age=10800;domain="+root_domain;
					axios.defaults.headers.common["X-Access-Token"] = response.data["access_token"];
					axios.defaults.headers.common["X-Refresh-Token"] = response.data["refresh_token"];
					dispatch(loginSuccess(response.data));
					dispatch(onLoadModal({ modalType: "", modalContent: "", showModal: false }));
					dispatch(fillUserId(response.data.user_id, response.data.username))
					if (payload.callback) payload.callback();
				} else {
					dispatch(flash(
						{ message: "Invalid Username/Password", type: "failure" },
						2500
					));
				}
				dispatch(requestApi(false));
			}).catch(error => {
				dispatch(AxiosCallError(error));
			});
		}
		else {
			dispatch(showErrorPage({
				'errorType': 'Application Error',
				'errorHeading': 'Unauthorized access',
				'errorMessage': 'Please make sure you are logged in as the user who received the invite'
			}))
			history.push('/error')
		}
	};
};

export const fillUserId = (user_id, email_id) => {
	return (dispatch, getState) => {
		let participants = getState().interview.participants
		let participant_id = participants.find(item => item.email === email_id).id
		let url=`${ep2}/api/participant/${participant_id}/`;
		let data = {
			"user_id" : user_id
		}
		axios.patch(url, data)
		.then(response => {
		})
		.catch(error => {
			dispatch(AxiosCallError(error));
		});	
	}
}


export const getMatchingCities = search_keyword => {
	return dispatch => {
		axios.get(
			`${catalog_ep}/api/catalogs/search_nodes/?node_type=2&search_query=${search_keyword}`
		).then(response => {
			dispatch(getMatchingCitiesSuccess({ response: response.data }));
		});
	};
};

export const getAllStates = () => {
	return dispatch => {
		axios.get(`${catalog_ep}/api/catalogs/`)
		.then(response => {
			response.data.forEach(data => {
				if (data.name.toString() === "Region Catalog") {
					axios.get(
						`${catalog_ep}/api/catalogs/${data.id}/nodes/?inc_child_node=NO`
					).then(response => {
						dispatch(getAllStatesSuccess({
							states: response.data
						}));
					}).catch(err => {
						dispatch(AxiosCallError(err));
					});
				}
			});
		}).catch(err => {
			dispatch(AxiosCallError(err));
		});
	};
};

export const FilterLocation = search_keyword => {
	return dispatch => {
		axios.get(`${catalog_ep}/api/location_suggest/?city_prefix=${search_keyword}`)
		.then(response => {
			dispatch(FilterLocationsSuccess({ locations: response.data }));
		});
	};
};

export const signupRequest = payload => {
	return dispatch => {
		axios.post(`${ep}/api/signup/`, payload).then(response => {
			if (response.success) {
				dispatch(
					onLoadModal({ modalType: "", modalContent: "", showModal: false })
				);
				dispatch(flash(
					{
						message:
							"Account created. Please check your email to activate your account",
						type: "success"
					},
					2500
				));
				dispatch(signUpSuccess());
			} else {
				let message = "";
				Object.keys(response.data).forEach(key => {
					message = response.data[key][0];
				});

				dispatch(flash({ message: message, type: "failure" }, 2500));
			}
		});
	};
};

export const SetPassword = (activation_key, payload) => {
	return dispatch => {
		axios
			.patch(`${ep}/api/activate/${activation_key}/`, payload)
			.then(response => {
				if (response.success) {
					window.location.href = "/home";
					dispatch(flash(
						{ message: "Password set successfully", type: "success" },
						2500
					));
				} else {
					dispatch(flash(
						{ message: "Problems setting password", type: "failure" },
						2500
					));
				}
			});
	};
};

export const resetPassword = payload => {
	return dispatch => {
		axios
			.post(`${ep}/api/password_reset/forgot_password/`, payload)
			.then(response => {
				if (response.success) {
					dispatch(flash(
						{
							message: "Reset password link sent to your email",
							type: "success"
						},
						2500
					));
					dispatch(
						onLoadModal({ modalType: "", modalContent: "", showModal: false })
					);
				} else {
					dispatch(flash(
						{
							message: "Email provied is not registered with College Connect",
							type: "failure"
						},
						2500
					));
				}
			});
	};
};

export const changePassword = payload => {
	return dispatch => {
		axios.post(`${ep}/api/password_reset/change_password/`, payload)
		.then(response => {
			if (response.success) {
				dispatch(flash(
					{
						title: "Password changed successfully",
						type: "success"
					},
					2500
				));
			} else {
				dispatch(flash(
					{
						title: "Problem while changing password.",
						type: "failure"
					},
					2500
				));
			}
		});
	};
};

export const fetchCompanyDetails = () => dispatch => {
	let company_id = localStorage.getItem("company_id")
	axios.get(`${ep}/api/company_data/${company_id}`)
	.then(response => {
		document.title = `${response.data.name} Online Assessment`
		dispatch(getcompanyInfoSuccess({company_details:response.data}))
	}).catch(err => {
		dispatch(AxiosCallError(err));
	})
};

export const logoutRequest = () => dispatch => {
	localStorage.clear();
	delete axios.defaults.headers.common["X-Access-Token"];
	delete axios.defaults.headers.common["X-Refresh-Token"];
	dispatch(logoutSuccess())
};
