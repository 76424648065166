import React from "react";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import * as actions from "../../store/actions/InterviewInviteActions"
import "./InterviewerLoginForm.scss";
import { connect } from "react-redux";
import { loginInterviewRequest } from '../../store/actions/AuthActions'
const FormItem = Form.Item;

function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class InterviewerLoginForm extends React.Component {

	componentDidMount() {
		// To disabled submit button at the beginning.
		this.props.form.validateFields();
	}

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let payload = {
					emailId: values.email,
					password: values.password,
					callback: () =>this.props.startInterview(this.props.interview_id)
				}
				this.props.loginHandler(payload);
			}
		});
	};

	render() {
		const {
			getFieldDecorator,
			getFieldsError,
			getFieldError,
			isFieldTouched
		} = this.props.form;

		// Only show error after a field is touched.
		const userNameError =
			isFieldTouched("email") && getFieldError("email");
		const passwordError =
			isFieldTouched("password") && getFieldError("password");
		return (
			<Form styleName="form" onSubmit={this.handleSubmit}>
				<FormItem
					validateStatus={userNameError ? "error" : ""}
					help={userNameError || ""}
				>
					{getFieldDecorator("email", {
						rules: [{ required: true, message: "Please input your Email!" }]
					})(
						<Input
							prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
							placeholder="Username"
						/>
					)}
				</FormItem>
				<FormItem
					validateStatus={passwordError ? "error" : ""}
					help={passwordError || ""}
				>
					{getFieldDecorator("password", {
						rules: [{ required: true, message: "Please input your Password!" }]
					})(
						<Input
							prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
							type="password"
							placeholder="Password"
						/>
					)}
				</FormItem>
				<FormItem>
					{getFieldDecorator('remember', {
						valuePropName: 'checked',
						initialValue: true,
					})(<Checkbox>Keep me signed</Checkbox>)}
					<Link styleName="form__forgot" to="#">
						Forgot Password?
					</Link>
					<Button type="primary" htmlType="submit" styleName="form__button" disabled={hasErrors(getFieldsError())}>
						Log in
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = state => {
	return {
		interview_id:state.interview.interview_id,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loginHandler: values => dispatch(loginInterviewRequest(values)),
		startInterview: (interview_id) => dispatch(actions.startInterview(interview_id))
	};
};

InterviewerLoginForm.propTypes = {
	form: PropTypes.object,
	validate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
	getFieldDecorator: PropTypes.func,
	loginHandler: PropTypes.func,
};


const WrappedInterviewerLoginForm = Form.create()(InterviewerLoginForm);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedInterviewerLoginForm);
