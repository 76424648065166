import React from "react";
import { Form, Button, Radio } from "antd";
import * as actions from "../../store/actions/InterviewActions"
import { connect } from "react-redux";
import { history } from "../../index";

import "./FeedbackForm.scss";

const FormItem = Form.Item;

function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class FeedbackForm extends React.Component {

	componentDidMount() {
		// To disabled submit button at the beginning.
		this.props.form.validateFields();
    }
    
    showInterviewCompletePage = () => {
        history.push("/interviewcomplete")
    }

	handleSubmit = e => {
		e.preventDefault();
		 this.props.form.validateFields((err, values) => {
			if (!err) {
                let data = {
                    'rating': values.rating,
                    'feedback': values.feedback
                }
                this.props.submitFeedback(data, this.showInterviewCompletePage)
            }
        })
	};

	render() {
		const {
			getFieldDecorator,
			getFieldsError,
			getFieldError,
			isFieldTouched
		} = this.props.form;
        const ratingError = isFieldTouched("rating") && getFieldError("rating");
        const feedbackError = isFieldTouched("feedback") && getFieldError("feedback");
		return (
			<Form  styleName="form" onSubmit={this.handleSubmit}>
                <FormItem
                    validateStatus={ratingError ? "error" : ""}
                    help={ratingError || ""}
                    label="Rating"
                    styleName="form-rating"
                >
                    {getFieldDecorator("rating", {
                        rules: [{ required: true}]
                    })(
                        <Radio.Group>
                            <Radio value={1}>Never Hire</Radio>
                            <Radio value={2}>Not Inclinded to Hire</Radio>
                            <Radio value={3}>Maybe</Radio>
                            <Radio value={4}>Inclined to hire</Radio>
                            <Radio value={5}>Strong Hire</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem
                    validateStatus={feedbackError ? "error" : ""}
                    help={feedbackError || ""}
                    label="Feedback"
                    styleName="form-feedback"
                >
                    {getFieldDecorator("feedback", {
                        rules: [{ required: true}]
                    })(
                        <textarea styleName="form-feedback__textarea"></textarea>
                    )}
                </FormItem>
                <FormItem styleName="form-button">
                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                        Submit
                    </Button>
                </FormItem>
			</Form>
		);
	}
}

const mapStateToProps = state => {
	return {
	};
};

const mapDispatchToProps = dispatch => {
	return {
        submitFeedback: (data, callback) => dispatch(actions.submitFeedback(data, callback))
	};
};


const WrappedFeedbackForm = Form.create()(FeedbackForm);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedFeedbackForm);
