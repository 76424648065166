import React, { Component } from "react";
import { withRouter } from "react-router";
import "./Footer.scss";

class Footer extends Component {
	render() {
		return (
			<div styleName="c-footer">
				<div styleName="c-footer__terms">
					<p style={{fontSize: "0.7rem"}}>
						{`Copyright © ${new Date().getFullYear()} CodeHall Technology Pvt Ltd. All rights reserved.`}
					</p>
				</div>
			</div>
		);
	}
}

export default withRouter(Footer);