import { handleActions } from "redux-actions";


export const InterviewInviteStatus = Object.freeze({
	ACTIVE: "ACTIVE",
	COMPLETED: "COMPLETED",
	CANCELLED: "CANCELLED"
});
const initialState = {
	interview_id: "",
	status: ""
};

const InterviewInviteReducer = handleActions(
	{
		VALIDATE_INTERVIEW_INVITE_SUCCESS: (state, action) => {
			return {
				...state,
				status: action.payload.status,
				interview_id: action.payload.interview_id,
				test_title: action.payload.test_title,
				role: action.payload.role,
				start_time: action.payload.start_time,
				participants: action.payload.participants 
			};
		},
		GET_INTERVIEW_SUCCESS: (state, action) => {
			return {
				...state,
				status: action.payload.status,
				interview_id: action.payload.id,
				test_title: action.payload.test_title,
				role: action.payload.role,
				start_time: action.payload.start_time,
				participants: action.payload.participants 
			};
		},

		SET_PARTICIPANTS: (state, action) => {
			return {
				...state,
				participants: action.payload
			}
		}
	},
	initialState
)

export default InterviewInviteReducer;