import React, { Component } from "react";
import './InterviewComplete.scss';
import { connect } from 'react-redux';
import * as actions from "../../store/actions"
import success_top from "../../assets/images/testcomplete/success-top.png";
import success_bot_r from "../../assets/images/testcomplete/success-bot-r.png";
import success_bot_l from "../../assets/images/testcomplete/success-bot-l.png";
//allows the user to change language
class InterviewComplete extends Component {

	componentDidMount(){
		this.props.logout()
	}

	render() {
		let user = this.props.user_detail ? this.props.user_detail.user.name : localStorage.getItem("interviewer_name");
		return (
			<div styleName="c-msg-box">
				<div styleName="c-msg-box__top-image">
					<img src={success_top} alt='success-top'/>
				</div>
				<h2 className="sub-header-font-size-mid bold">The interview has been ended by {user? user : "interviewer"}.</h2>
				<p styleName="c-msg-box__instruction">
					Thank you for attending the interview. You can access the candidate's code <br/>
                        through the portal
				</p>
				<div styleName="c-msg-box__bottom-image">
					<img styleName="c-msg-box__bottom-image-left" src={success_bot_l} alt='success-bot-r'/>
					<img styleName="c-msg-box__bottom-image-right" src={success_bot_r} alt='success-bot-l'/>
				</div>
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(actions.logout())
	};
}

const mapStateToProps = state => {
	return {
       user_detail: state.events.end_interview_user_detail
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(InterviewComplete);
