import { handleActions } from "redux-actions";

const initialState = {
    last_editor_data: '',
    last_language: '',
    chat_data: [],
    notes: [],
    current_page: '',
}

const EventReducer = handleActions(
    {
        SET_LAST_CODE: (state, action) => {
            return {
                ...state,
                last_editor_data: action.payload
            }
        },
        SET_LAST_LANGUAGE: (state, action) => {
            return {
                ...state,
                last_language: action.payload
            }
        },
        SET_CHAT_EVENTS: (state, action) => {
            return {
                ...state,
                chat_data: action.payload ?action.payload : ""
            }
        },
        GET_SUPPORTED_LANGUAGES: (state,action) => {
			return {
				...state,
				supported_languages: action.payload
			}
        },
        SET_INITIAL_NOTES: (state, action) => {
            return {
                ...state,
                notes: action.payload
            }
        },
        SET_NOTES: (state, action) => {
            return {
                ...state,
                notes: [...state.notes, action.payload]
            }
        },
        UPDATE_NOTE: (state, action) => {
            let updated_notes = state.notes.map(obj => {
                if (obj.id === action.payload.id) {
                    return {
                        ...obj,
                        data: action.payload.data
                    }
                }
                return obj;
            })
            return {
                ...state,
                notes: updated_notes
            }
        },
        SET_END_INTERVIEW_USER_DETAIL: (state,action) => {
            return {
                ...state,
                end_interview_user_detail: action.payload
            }
        },
        SET_CURRENT_PAGE: (state, action) => {
            return {
                ...state,
                current_page: action.payload
            }
        },
        SET_HEARTBEAT: (state, action) => {
            return {
                ...state,
                heartbeat: action.payload
            }
        }
    },
    initialState
)

export default EventReducer